<template>
  <b-modal
    ref="game-cat-modal"
    hide-footer
    :title="modalTitle"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      id="game-cat-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="ชื่อหมวดเกม"
        label-class="text-primary"
      >
        <b-form-input
          id="input-cat-name"
          v-model="input.name"
          type="text"
          placeholder="ชื่อหมวดเกม"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="เรียงลำดับ"
        label-class="text-primary"
      >
        <b-form-input
          id="input-cat-sort"
          v-model="input.sort"
          type="text"
          placeholder="เรียงลำดับ"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="สถานะ" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-status"
          v-model="input.status"
          :options="statusOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        ></b-form-radio-group>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess || !input.name">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import GameService from '@/services/gameService'
import Swal from 'sweetalert2'

export default {
  name: 'GameCatModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {
        _id: null,
        name: null,
        sort: 0,
        status: 'Active'
      }
    }
  },
  data() {
    return {
      input: {
        _id: null,
        name: null,
        sort: 0,
        status: 'Active'
      },
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    modalTitle() {
      return this.input._id ? 'แก้ไขหมวดเกม' : 'เพิ่มหมวดเกม'
    },
    statusOptions() {
      return [
        { text: 'ใช้งาน', value: 'Active' },
        { text: 'ไม่ใช้งาน', value: 'Inactive' }
      ]
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['game-cat-modal'].show()
    },
    hideModal() {
      this.$refs['game-cat-modal'].hide()
    },
    handleSubmit() {
      if(this.input._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      if(this.input.name) {
        GameService.saveCategory(this.input)
        .then((response)=>{

          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
          }else{
            throw new Error(response?.message)
          }
        })
        .catch((e)=>{

          Swal.fire({
            title: 'ผิดพลาด!',
            text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    },
    update() {
      if(this.input.name) {
        GameService.updateCategory(this.input._id, this.input)
        .then((response)=>{
          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }else{
            throw {
              massage: response
            }
          }
        })
        .catch((e)=>{
          Swal.fire({
            title: 'ผิดพลาด!',
            text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
